// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'

// ** Components
import Autocomplete from 'src/layouts/components/Autocomplete'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import Logo from 'src/@core/components/logo'

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  return (
    <Box className='actions-left' sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {hidden ? (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <IconButton color='inherit' onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
          <Box mt={'10px'}>
            <Link to='/' sx={{ textDecoration: 'none' }}>
              <Logo />
            </Link>
          </Box>
          <Box display='flex' flexDirection='row' alignItems='center' gap={3} justifyContent={'flex-end'}>
            <Button
              variant='contained'
              size='small'
              as={Link}
              to='https://rider.zanride.com/login'
              sx={{ textDecoration: 'none' }}
            >
              Login
            </Button>
          </Box>
        </Box>
      ) : null}

      {/* {/ <Autocomplete hidden={hidden} settings={settings} /> /} */}
    </Box>
  )
}

export default AppBarContent
