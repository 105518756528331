import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
import CareersPage from './pages/careers'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'

const HomePage = React.lazy(() => import('./pages/home'))
const BecomeDriverPage = React.lazy(() => import('./pages/becomeDriver'))
const AboutusPage = React.lazy(() => import('./pages/aboutus'))
const ContactPage = React.lazy(() => import('./pages/contact'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsandconditionPage = React.lazy(() => import('./pages/Termsandcondition'))
const LetterFromCEODetailed = React.lazy(() => import('./pages/aboutus/letterFromCEODetailed'))
const BlogPage = React.lazy(() => import('./pages/blog'))
const Blogdetails = React.lazy(() => import('./pages/blog/blogdetails'))
const FaqDetailsPage = React.lazy(() => import('./pages/faq'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))
const ThanksPage = React.lazy(() => import('./pages/thankYou'))
const SitemapPage = React.lazy(() => import('./pages/sitemap'))


function App() {
  return (
    <Suspense fallback={<FallbackSpinner />}>
      <Routes>
        <Route
          element={
            <BlankLayout>
              <Outlet />
            </BlankLayout>
          }
        >
          <Route path='/401' element={<Page401 />} />
          <Route path='/404' element={<Page404 />} />
        </Route>

        <Route
          element={
            <UserLayout>
              <Outlet />
            </UserLayout>
          }
        >
          <Route path='/' element={<HomePage />} />
          <Route path='/become-a-driver' element={<BecomeDriverPage />} />
          <Route path='/about-us' element={<AboutusPage />} />
          <Route path='/careers' element={<CareersPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='/terms-and-condition' element={<TermsandconditionPage />} />
          <Route path='/letter-form-our-ceo' element={<LetterFromCEODetailed />} />
          <Route path='/blog' element={<BlogPage />} />
          <Route path="/blog/:id" element={<Blogdetails />} />
          <Route path='/faq' element={<FaqDetailsPage />} />
          <Route path='/thank-you' element={<ThanksPage />} />
          <Route path='/sitemap' element={<SitemapPage />} />


        </Route>

        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>
    </Suspense>
  )
}

export default App
